import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      perPage: 8,
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'code', 
          label: 'รหัสสาขา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2 py-1.5',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'ชื่อสาขา',
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'text-gray-400 td-top text-xs-1/2 py-1.5',
          sortable: true 
        },
        { 
          key: 'address', 
          label: 'ที่อยู่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2 py-1.5' 
        },
        { 
          key: 'tel', 
          label: 'เบอร์โทรศัพท์', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-left text-xs-1/2 py-1.5'  
        },
        { 
          key: 'contract', 
          label: 'ผู้ติดต่อ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-center text-xs-1/2 py-1.5'  
        },
        { 
          key: 'action', 
          label: '#', 
          sortable: true, 
          thClass: 'font-weight-bold text-right text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2 py-1.5'  
        }
      ],
      items: [],
     }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.items = []
        this.onSearchHandler(this.currentPage) 
      }
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onSelected (item) {
      this.$emit('onBranchesCloseHandler', {
        status: true,
        data: item
      })
    },
    onClose () {
      this.$emit('onBranchesCloseHandler', {
        status: false
      })
    },
    async onSearchHandler (currentPage) {
      this.apiStatus = 'กำลังค้นหา..'
      this.busy = true
      // this.items = []
      this.totalItem = 0

      const page = currentPage ? currentPage : 1
      const url = `${Vue.prototype.$host}/api/v1/branch?page=${page}&perPage=8&keyword=${this.textInput}`
      const param = {
        keyword: this.keyword,
        url: url
      }
      const result = await this.$store.dispatch('Branches/search', param, { root: true })
      setTimeout(() => {
        this.busy = false
        if (result) {
          this.currentData = result.data
          this.items = result.data.data
          this.totalItem = result.data.total
          this.apiStatus = 'ไม่พบข้อมูล.'
        } else {
          this.onExceptionHandler()
          this.apiStatus = 'ไม่พบข้อมูล.'
        }
      }, 500)
    }
  }
}
