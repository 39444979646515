import Vue from "vue"
import RequestItem from '@/components/widgets/request-item'
import Branches from '@/components/widgets/branches'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from "vue2-datepicker"
import moment from "moment"

const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    RequestItem,
    Branches,
    DatePicker
  },
  data() {
    return {
      branchesOpend: false,
      productOpened: false,
      loading: false,
      form: {
        document_no: "",
        date: new Date(),
        type: 1,
        until: null,
        payment_terms: 1,
        note: '',
        branch_id: null,
      },
      fields: [
        {
          key: "color",
          label: "สี",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-normal text-xs-1/2",
          sortable: true,
        },
        {
          key: "thickness",
          label: "ความหนา",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "quantity",
          label: "จำนวน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "action",
          label: "#",
          thClass: "text-xs-1/2 font-weight-bold",
          class: "text-right text-xs-1/2",
        }
      ],
      branchesFields: [
        { 
          key: 'code', 
          label: 'รหัสสาขา',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'font-weight-normal td-top text-xs-1/2 py-1'
        },
        { 
          key: 'name', 
          label: 'ชื่อสาขา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 py-1',
          sortable: true 
        },
        { 
          key: 'address', 
          label: 'ที่อยู่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top w-xs max-w-xs text-xs-1/2 py-1' 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-right text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-right text-xs-1/2 py-1'  
        }
      ],
      branches: [],
      products: []
    }
  },
  methods: {
    previous() {
      this.$router.push('/request')
    },
    onselectProduct() {
      this.productOpened = !this.productOpened
    },
    onselectBranches() {
      this.branchesOpend = !this.branchesOpend
    },
    async onSubmitForm() {
      this.loading = true
      const formData = new FormData()

      if(this.branches.length === 0){
        this.loading = false  
        this.onExceptionHandler("กรุณาเลือกสาขา")
        return
      }

      if(this.products.length === 0){
        this.loading = false  
        this.onExceptionHandler("กรุณาเลือกรูปแบบอย่างน้อย 1")
        return
      }

      if(this.form.date === null){
        this.loading = false  
        this.onExceptionHandler("กรุณาเลือกวันที่")
        return
      }

      this.form.branch_id = this.branches[0].id
      formData.append('document_no', this.form.document_no)
      formData.append('date', moment(this.form.date))
      formData.append('note', this.form.note)      
      formData.append('branch_id', this.form.branch_id)
      formData.append('branches', JSON.stringify(this.branches))
      formData.append('products', JSON.stringify(this.products))

      try {
        await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/document/request`, formData)
        this.products = []
        this.branches = []
        this.loading = false  

        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            '<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>'+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            cancelButtonColor: '#dc4a38',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: "ตกลง",
            showCancelButton: false,
            showCloseButton: true,
        }).then(async confirm => {
          if(confirm.value){
            this.$router.push('/request')
          }
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onRemoveBranches(id) {
      const index = _.findIndex(this.branches, function(o) { return o.uuid == id })
      this.branches.splice(index, 1)
    },
    onRemoveProduct(index) {
      this.products.splice(index, 1)
    },
    onInitProducts(event) {
      this.productOpened = false
      if (event.status) {
        this.products.push({
          color: event.data.color,
          thickness: event.data.thickness,
          brand: event.data.brand,
          quantity: event.data.quantity,
          note: event.data.note? event.data.note: " "
        })
      }
    },
    onInitBranches(event) {
      this.branchesOpend = false
      if (event.status) {
        let items = event.data
        if(this.branches.length === 0) {
          this.branches.push(items)
        } else {
          const item = _.find(this.branches, { uuid: items.uuid })
          if (!item) {
            this.branches.push(items)
          }
        }
      }
    }
  }
}